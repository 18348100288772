.page-home {
  .section {
    .btn {
      width: 100%;
      @include transition(all 0.2s);
      @include media-breakpoint-up(lg) { width: 200px; }

      @media (min-width: 1440px) { width: 220px; }
    }

    &.secondary {
      padding-top: 48px;
      padding-bottom: 24px;

      @include media-breakpoint-up(lg) {
        padding-top: 80px;
        padding-bottom: 80px;
      }

      .row {
        @include media-breakpoint-up(lg) { max-height: 560px; }
      }
    }
  }
}

.home-banner {
  width: 100%;
  height: 100vh;
  position: relative;
  padding-top: 100px;
  margin-bottom: 80px;

  @include media-breakpoint-up(lg) {
    padding-top: 0;
    margin-bottom: 100px;
    height: 912px;
  }

  .container {
    max-width: 1440px;
    height: 100%;
    padding: 0 24px;
    position: relative;

    @include media-breakpoint-up(lg) { padding: 0 72px; }
  }
}

.home-headline {
  width: calc(100vw - 48px);
  border: 2px solid $ui-secondary;
  border-bottom-width: 0;
  padding: 15px 15px 5px;
  text-align: center;

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 18%;
    left: 0;
    width: 55%;
    margin-left: 72px;
    padding: 25px;
    text-align: left;
    bottom: 55%;
    border-bottom-width: 2px;
  }

  h1 {
    margin-bottom: 0;
    color: #fff;
    font-size: 48px;
    font-family: $ff-sans;
    font-weight: 400;
    line-height: .96;

    @include media-breakpoint-up(lg) {
      font-size: 100px;
    }
  }
}

.home-headline-text {
  width: calc(100vw- 48px);
  border: 2px solid $ui-secondary;
  border-top-width: 0;
  padding: 15px;

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 23%;
    right: 0;
    width: 38%;
    padding: 30px;
    padding-left: 125px;
    margin-right: 72px;
    border-top-width: 2px;
  }

  p {
    margin-bottom: 25px;
    font-size: 16px;
    color: #fff;
    font-family: $ff-text;
    line-height: 1.55;
    text-align: center;

    @include media-breakpoint-up(lg) {
      font-size: 18px;
      text-align: left;
    }
  }

  .btn {
    width: 100%;
    @include transition(all 0.2s);

    @include media-breakpoint-up(lg) {
      width: 220px;
    }
  }
}

.home-banner-img {
  background-color: #2d71c8;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    height: auto;
  }

  img {
    width: 200%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;

    @include media-breakpoint-up(sm) {
      width: 180%;
      object-position: left bottom;
    }

    @include media-breakpoint-up(md) { width: 140%; }
    @include media-breakpoint-up(lg) { width: 100%; }
  }
}

.section-slider {
  margin-top: 30px;
  margin-right: 20px;

  @include media-breakpoint-up(xl) {
    margin-right: 40px;
  }

  .row {
    flex-wrap: nowrap;

    @include media-breakpoint-down(md) {
      margin-left: -10px;
      margin-right: -10px;
    }

    > * {
      @include media-breakpoint-down(md) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}

.card-location {
  border-radius: 0;
  border-color: $ui-secondary;
  border-width: 1.5px;
  height: 100%;

  .card-body { padding: 10px; }

  .card-img {
    border-radius: 0;
    margin-bottom: 15px;
  }

  .card-title {
    font-size: 24px;
    font-family: $ff-sans;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 15px;

    @media (min-width: 1440px) {
      font-size: 26px;
      line-height: 1.2;
    }
  }

  .card-text {
    margin-bottom: 0;
    font-size: 16px;
    font-family: $ff-text;

    @media (min-width: 1440px) {
      font-size: 18px;
    }
  }

  .card-footer {
    background-color: transparent;
    border-top-width: 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link {
      font-size: 16px;
      font-weight: 500;
      font-family: $ff-serif;
      color: $ui-secondary;
      text-decoration: none;
    }

    .btn {
      width: 50%;
    }
  }
}

.slider-pager {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  margin-right: -20px;

  @include media-breakpoint-up(lg) {
    margin-top: 48px;
    margin-right: -40px;
  }
}

.slider-prev,
.slider-next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 2px solid $ui-secondary;
  color: $ui-secondary;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $ui-secondary;
  }

  i {
    font-size: 24px;
    line-height: 1;
  }

  &.disabled {
    opacity: .5;
    cursor: default;
  }
}

.slider-control {
  display: flex;
  align-items: center;

  span {
    width: 8px;
    height: 4px;
    background-color: $bd-color;

    + span { margin-left: 8px; }

    &.active {
      width: 46px;
      background-color: $ui-secondary;
    }
  }
}
