.page-404 {
  background-color: $bg-light;
}

.header-404 {
  .container {
    @include media-breakpoint-up(lg) { justify-content: center; }
  }

  .nav-header {
    @include media-breakpoint-up(lg) { display: none; }
  }
}

.section-404 {
  height: 100vh;
  padding-top: 120px;
  padding-bottom: 100px;

  @include media-breakpoint-up(sm) {
    padding-bottom: 72px;
  }

  .container {
    max-width: 1440px;
    height: 100%;
    padding: 0 24px;

    @include media-breakpoint-up(lg) { padding: 0 72px; }
  }
}

.footer-404 {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100px;

  @include media-breakpoint-up(sm) { height: 72px; }

  .container {
    max-width: 1440px;
    padding: 24px;
    color: #fff;

    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    @include media-breakpoint-up(lg) {
      padding: 24px 72px;
    }
  }

  .footer-item + .footer-item {
    @include media-breakpoint-up(sm) { margin-top: 0; }
  }
}

.card-error {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1.5px solid $ui-secondary;

  h1 {
    font-size: 140px;
    font-family: $ff-sans;
    font-weight: 400;
    line-height: .6;
    letter-spacing: -2px;
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
      font-size: 240px;
      margin-bottom: 48px;
    }
  }

  p {
    font-size: 24px;
    font-family: $ff-text;
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) { margin-bottom: 48px; }
  }
}
