.page-house {
  .section-floor {
    .row { max-height: 500px; }
  }
}

.banner-wrapper {
  position: relative;
  color: $body-color;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    color: #fff;
    display: block;
  }

  h2 {
    font-size: 40px;
    font-family: $ff-sans;
    font-weight: 400;
    line-height: 1;
    order: 1;
    margin-bottom: 15px;
    margin-top: 25px;

    @include media-breakpoint-up(lg) {
      font-size: 80px;
      position: absolute;
      top: 12%;
      left: 5%;
      z-index: 100;
      margin-bottom: 0;
      margin-top: 0;
    }

    @media (min-width: 1440px) {
      left: 8%;
    }
  }

  h6 {
    font-family: $ff-text;
    font-size: 16px;
    order: 4;
    margin-bottom: 15px;

    @include media-breakpoint-up(lg) {
      font-size: 18px;
      font-weight: 400;
      position: absolute;
      top: 25%;
      left: 5%;
      z-index: 100;
      margin-bottom: 0;
    }

    @media (min-width: 1440px) {
      left: 8%;
    }
  }

  .banner-text {
    order: 3;

    @include media-breakpoint-up(lg) {
      position: absolute;
      bottom: 69%;
      right: 5%;
      left: 61%;
      z-index: 100;
      width: 34%;
    }

    @media (min-width: 1440px) {
      right: 8%;
      left: 61%;
    }

    p {
      font-family: $ff-text;
      font-size: 16px;
      margin-bottom: 30px;

      @include media-breakpoint-up(lg) { font-size: 18px; }
    }
  }

  .section-img {
    order: 2;
  }

  .btn {
    min-width: 200px;
    order: 5;

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 35%;
      left: 61% ;
      z-index: 100;
    }
  }
}

.banner { background-color: $ui-secondary; }

.banner-body {
  padding: 20px 24px;
  color: #fff;
  font-family: $ff-serif;
  font-size: 26px;

  @include media-breakpoint-up(lg) {
    padding: 24px 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h6,p {
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.2;
  }

  .btn-white {
    border-color: #fff;
    border-width: 1.5px;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 500;
    letter-spacing: .5px;
    display: flex;
    align-items: center;

    &:focus {
      box-shadow: none;
    }
  }
}
