
.container-label {
  text-transform: uppercase;
  font-size: $font-size-sm;
  letter-spacing: 1px;
  display: block;
}

.section-label {
  font-size: $font-size-sm;
  font-weight: 500;
  display: block;
}

.xs-label { font-size: 13px; }
.xxs-label { font-size: 12px; }
