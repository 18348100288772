
.section-header {
  padding-top: 100px;
  padding-bottom: 0;

  @include media-breakpoint-up(lg) { padding-top: 120px; }

  .container {
    padding: 24px;
    @include media-breakpoint-up(lg) { padding: 72px; }
  }

  .section-title {
    font-size: 56px;
    margin-left: -2px;

    @include media-breakpoint-up(lg) {
      text-indent: -5px;
      margin-left: 0;
      font-size: 80px;
    }
  }
}

.card-maps {
  position: relative;
}

.card-map {
  position: relative;

  img {
    width: 100%;
    height: auto;
    margin: auto;
  }
}

.media-location {
  display: none;
  flex-direction: column;
  cursor: pointer;

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    border-left: 2px solid transparent;
  }

  &.active {
    display: flex;
    border-left-color: $ui-secondary;
  }

  + .media-location { margin-top: 40px; }

  .media-img {
    width: 100%;
    height: 200px;
    border-radius: 4px;
    position: relative;

    @include media-breakpoint-up(lg) {
      width: 160px;
      height: 160px;
    }

    @media (min-width: 1440px) {
      width: 180px;
      height: 180px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      //border-radius: inherit;
    }
  }

  .media-badge {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: rgba(#fff, .75);
    padding: 2px 10px;
    border-radius: 20px;
    font-family: $ff-text;
  }

  .media-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 20px;

    @include media-breakpoint-up(lg) {
      padding-top: 0;
      padding-left: 15px;
    }
  }

  h3 {
    font-size: 24px;
    font-weight: 400;
    font-family: $ff-sans;
    line-height: 1.15;

    @media (min-width: 1440px) {
      font-size: 26px;
    }
  }

  p { font-family: $ff-text; }

  .link { margin-top: auto; }
}

.point-logo{
  float: left;
  margin: 0 !important;
}

.map-point {
  width: 9vw;
  height: 45px;
  position: absolute;

  &.t-5p { top: 5%; }
  &.l-45p { left: 45%; }

  &.t-28p { top: 28%; }
  &.l-10p { left: 10%; }

  &.t-20p { top: 20%; }
  &.l-10p { left: 10%; }

  &.t-15p { top: 15%; }
  &.l-15p { left: 15%; }

  &.t-24p { top: 24%; }
  &.l-25p { left: 33%; }

  &.t-58p { top: 59%; }
  &.l-51p { left: 58%; }

  &.t-65p { top: 63.4%; }
  &.l-26p { left: 33.3%; }

  &.t-68p { top: 68%; }
  &.l-27p { left: 32%; }

  &.t-85p { top: 85%; }
  &.l-63p { left: 70%; }


  &.t-25p { top: 25%; }
  &.l-26p { left: 33.3%; }

  &.t-60p { top: 60%; }
  &.l-53p { left: 59%; }

  &.t-66p { top: 68%; }
  &.l-24p { left: 33.5%; }

  &.t-81p { top: 70%; }
  &.l-28p { left: 32%; }

  &.t-55p { top: 55%; }

  &.t-61p { top: 61%; }
  &.t-64p { top: 64%; }

  &.t-87p { top: 87%; }
  &.l-64p { left: 70%; }


  &.t-78p { top: 78%; }
  &.t-79p { top: 79%; }
  &.t-82p { top: 82%; }
  &.l-23p { left: 32.2%; }
  &.l-50p { left: 50%; }
  &.l-58p { left: 58%; }
  &.l-62p { left: 62%; }
  &.l-66p { left: 66%; }

  @media screen and (max-width: 991px) {
    width: 15vw;
  }
  @media screen and (min-width: 1500px) {
    width: 8vw;
  }
}

.point-logo {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  background-color: #236092;
  position: relative;

  img {
    width: 12px;
    height: auto;

    @include media-breakpoint-up(lg) {
      width: 18px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -12px;
    right: 0;
    border-top: 6px solid #173D5C;
    border-left: 6px solid transparent;
    border-bottom: 6px solid transparent;

    @include media-breakpoint-up(lg) {
      bottom: -24px;
      border-top-width: 12px;
      border-left-width: 12px;
      border-bottom-width: 12px;
    }
  }
}
