.slick-track {
    display: flex !important;
}

.slick-slide {
    height: auto;
    margin-bottom: 0;
    object-fit: cover;
}

.slick-slide {
    margin-right: 30px;
}

.slick-list {
    margin-right: -30px !important;
}

.slick-slide > div {
    height: 100%;
}

.slick-slide .card {
    display: flex !important;
    cursor: grab;
}

.card-body {
    flex: 1 1 auto;
}

.slick-slide img {
    display: block;
    height: 450px;
    object-fit: cover;
}

.disabled-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    background-color: #dedddd;
    color: #fff;
    font-family: "Alegreya SC", serif;
    font-weight: 500;
    height: 50px;
    border-radius: 0;
    text-transform: uppercase;
    cursor: not-allowed;

}

.light-nav::before {
    bottom: -100vh !important;
}

.slick-dots li {
    list-style: none !important;
    width: 8px !important;
    height: 4px !important;
    background-color: #dcddde !important;
}

.slick-dots li.slick-active {
    background: #dead00 !important;
    width: 46px !important;
}

.show {
    display: block !important;
}

.about-item {
    margin-bottom: 20px;
}

.required label::before {
    content: "*";
    display: inline;
    padding: 2px;
    color: #ddad00;
}

.form-control {
    padding-left: 5px !important;
}

.circular-divider {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: white;
    display: inline-block;
    margin: 1px 5px;
}

.location-description > * {
    font-size: 1rem !important;
    font-family: "Georgia", serif;
    font-weight: 400;
}

.banner_text.textbg, .home-headline.textbg h1, .home-headline-text.textbg > p {
    padding: 10px;
    background: hsl(0deg 1% 40% / 42%);
    backdrop-filter: blur(5px);
}

.map-info {
    width: 13.5rem;
    background: #000000ab;
    color: white;
    padding: 2px 5px;
    margin-top: 16px;
    border-radius: 5px;
    text-align: center;
    font-size: 0.63rem;
}

.map-info h3 {
    text-transform: uppercase;
    font-size: 0.7rem;
    margin-bottom: 2px;
}

.modal-content {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.light-nav {
    background: hsl(0deg 1% 40% / 42%);
    backdrop-filter: blur(5px);
}

.banner_text a {
    color: white;
}

@media only screen and (max-width: 991px) {
    .page-house .section-floor .row {
        max-height: none !important;
    }

    .location-image-view {
        background-attachment: initial;
        background-size: cover;
        background-position: center center;
        height: 100vh !important;
        width: 100vw !important;
    }

    .nav-footer {
        margin-top: 40px;
    }

    .banner_text a {
        color: black;
    }

    .banner_text .circular-divider {
        background: black;
    }
}

element.style {
    max-width: 500px;
}

.team.board {
    background-color: white !important;
}

.card-team {
    width: 70% !important;
}

.team .slick-list {
    margin-right: -100px !important;
}

.link.separator {
    padding: 7px;
}

@media (min-width: 992px) {
    .banner-wrapper h2,
    .banner-wrapper .h2 {
        max-width: 500px;
        font-size: 80px;
        position: static !important;
    }

    .banner-wrapper h6,
    .banner-wrapper .h6 {
        position: static !important;
    }

    .banner_text {
        position: absolute;
        bottom: 67%;
        left: 5%;
        font-size: 80px;
        z-index: 100;
        margin-top: 0;
    }

    .banner_text h2 {
        font-size: 80px;
    }

    .team.section {
        padding: 50px 0px;
    }

    .house-content > * {
        font-size: 32px !important;
        top: 12%;
        left: 5%;
        z-index: 100;
        margin-bottom: 0;
        margin-top: 0;
        font-family: "Alegreya Sans SC", sans-serif;
        font-weight: 400;
        line-height: 1;
        order: 1;
    }

    .nav-footer {
        width: 115px !important;
    }

}
