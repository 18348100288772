
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  z-index: 900;
  @include transition(all 0.2s);

  @include media-breakpoint-up(lg) {
    height: 120px;
  }

  &::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 330px;
    background-color: $bg-light;
    z-index: 40;
    transform: translateX(100%);
    visibility: hidden;
    opacity: 0;
    @include transition(all 0.2s);

    @include media-breakpoint-up(lg) { display: none; }
  }

  .container {
    height: 100%;
    max-width: 1440px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
      padding-left: 72px;
      padding-right: 72px;
    }
  }

  &.show-nav {
    &::before {
      transform: translateX(0);
      visibility: visible;
      opacity: 1;
    }

    .nav-header {
      transform: translateX(0);
      visibility: visible;
      opacity: 1;
    }

    .header-menu::before {
      transform: rotate(45deg);
      top: calc(50% - 1px);
      background-color: $tx-dark;
    }

    .header-menu::after {
      transform: rotate(-45deg);
      bottom: calc(50% - 1px);
      background-color: $tx-dark;
    }

  }

  &.dark {
    .header-logo img { display: none; }
    .header-logo .light {
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    .header-logo .light-mobile {
      display: block;
      @include media-breakpoint-up(lg) { display: none; }
    }

    .nav-header .nav-link { color: $body-color; }

    .header-menu::before,
    .header-menu::after {
      background-color: $tx-dark;
    }
  }

  &.scroll {
    background-color: #fff;

    .header-logo img { display: none; }
    .header-logo .light {
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    .header-logo .light-mobile {
      display: block;
      @include media-breakpoint-up(lg) { display: none; }
    }

    .nav-header .nav-link { color: $body-color; }

    .header-menu::before,
    .header-menu::after {
      background-color: $tx-dark;
    }
  }
}

.header-logo {
  width: 140px;
  height: calc(100% - 76px);
  display: block;

  @include media-breakpoint-up(lg) {
    height: calc(100% - 32px);
  }

  img {
    width: auto;
    height: 100%;
    display: none;

    &.dark-mobile {
      display: block;
      @include media-breakpoint-up(lg) { display: none; }
    }

    &.dark {
      display: none;
      @include media-breakpoint-up(lg) { display: block; }
    }
  }
}

.nav-header {
  position: fixed;
  right: 0;
  width: 330px;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  transform: translateX(100%);
  visibility: hidden;
  opacity: 0;
  @include transition(all 0.2s);

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    width: auto;
    position: static;
    visibility: visible;
    opacity: 1;
    transform: none;
  }

  &:first-of-type {
    top: 100px;
    @include media-breakpoint-up(lg) { top: 0; }

    .nav-link:first-child {
      @include media-breakpoint-up(lg) {
        display: none;
        + .nav-link { margin-left: 0; }
      }
    }
  }

  &:last-of-type {
    top: 100px + 154px;
    @include media-breakpoint-up(lg) { top: 0; }
  }


  .nav-link {
    padding: 0;
    font-size: 18px;
    font-weight: 700;
    font-family: 'Georgia', serif;
    color: $tx-dark;
    position: relative;
    @include transition(all 0.2s);

    @include media-breakpoint-up(lg) {
      color: #fff;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background-color: $ui-secondary;
      display: none;
    }

    &:hover::before {
      display: block;
    }

    + .nav-link {
      margin-top: 24px;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-left: 40px;
      }
    }

    &.active {
      &::before { display: block; }
    }
  }
}

.header-menu {
  position: relative;
  width: 42px;
  height: 12px;
  z-index: 50;

  @include media-breakpoint-up(lg) { display: none; }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #fff;
    transform-origin: center;
    @include transition(all 0.2s);
  }

  &::before { top: 0; }
  &::after { bottom: 0; }
}
