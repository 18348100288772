.page-lifestyle {
  .section-header {
    background-color: $bg-light;
  }

  .section-title {
    white-space: normal;
    line-height: .95;
  }

  .section-text {
    font-size: 16px;
    @include media-breakpoint-up(xl) { font-size: 18px; }
  }
}

.section-carefree {
  background-color: $bg-light;
  margin-bottom: 50px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 100px;
  }

  .section-title {
    @include media-breakpoint-up(lg) {
      width: 50%;
    }
  }

  .row {
    @include media-breakpoint-up(lg) {
      max-height: 560px;
    }
  }
}
