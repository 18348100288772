.section {
  padding: 50px 0;

  @include media-breakpoint-up(lg) { padding: 80px 0; }

  .container {
    max-width: 1440px;
    padding-left: 24px;
    padding-right: 24px;

    @include media-breakpoint-up(lg) {
      padding-left: 72px;
      padding-right: 72px;
    }
  }

  &.secondary { background-color: $bg-light; }
  &.section-overflow { overflow: hidden; }
}

.section-title {
  font-family: $ff-sans;
  font-size: 54px;
  font-weight: 400;
  line-height: .76;
  text-indent: -2px;
  margin-bottom: 25px;

  @include media-breakpoint-up(lg) {
    font-size: 68px;
    white-space: nowrap;
  }

  @media (min-width: 1440px) {
    font-size: 80px;
    letter-spacing: -1.5px;
  }
}

.section-subtitle {
  font-size: 24px;
  font-weight: 400;
  font-family: $ff-sans;
}

.section-text {
  font-family: $ff-text;
  margin-bottom: 20px;
  line-height: 1.56;

  @include media-breakpoint-up(lg) { margin-bottom: 30px; }
}

.section-img-wrapper {
  position: relative;
  padding-left: 10px;
  padding-bottom: 10px;

  @include media-breakpoint-up(lg) {
    padding-left: 15px;
    padding-bottom: 15px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    right: 10px;
    bottom: 0;
    border: 2px solid $ui-secondary;
    z-index: 5;

    @include media-breakpoint-up(lg) {
      top: 15px;
      right: 15px;
    }
  }

  &:hover,
  &:focus {
    cursor: pointer;
    img { transform: scale(1.25); }
  }

  &.two {
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 10px;
    padding-right: 10px;

    @include media-breakpoint-up(lg) {
      padding-left: 0;
      padding-bottom: 0;
      padding-top: 15px;
      padding-right: 15px;
    }

    &::before {
      top: 0;
      left: 10px;
      right: 0;
      bottom: 10px;

      @include media-breakpoint-up(lg) {
        top: 0;
        left: 15px;
        right: 0;
        bottom: 15px;
      }
    }
  }


  &.three {
    padding-left: 0;
    padding-bottom: 10px;
    padding-right: 10px;

    @include media-breakpoint-up(lg) {
      padding-left: 0;
      padding-bottom: 15px;
      padding-right: 15px;
    }

    &::before {
      top: 10px;
      left: 10px;
      right: 0;
      bottom: 0;

      @include media-breakpoint-up(lg) {
        top: 15px;
        left: 15px;
        right: 0;
        bottom: 0;
      }
    }
  }

  &.four {
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 0;

    @include media-breakpoint-up(lg) {
      padding-left: 15px;
      padding-top: 15px;
      padding-bottom: 0;
    }

    &::before {
      top: 0;
      left: 0;
      right: 10px;
      bottom: 10px;

      @include media-breakpoint-up(lg) {
        top: 0;
        left: 0;
        right: 15px;
        bottom: 15px;
      }
    }
  }
}

.section-img {
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    @include transition(all 0.25s);
  }
}

.section-lightslider {
  .lSSlideOuter .lightSlider {
    display: flex;
    align-items: stretch;
    height: auto !important;

    > .card {
      float: none;
      height: inherit !important;
    }
  }


  .lSPager {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px !important;

    li {
      list-style: none;
      width: 8px;
      height: 4px;
      background-color: $bd-color;

      + li { margin-left: 8px; }

      &.active {
        width: 46px;
        background-color: $ui-secondary;
      }

      //&:last-child { display: none; }
    }
  }

  .slider-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: -20px;
  }
}
