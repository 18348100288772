.section-team {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.page-about {
  .section-text {
    font-size: 16px;
    @include media-breakpoint-up(lg) { font-size: 18px; }
  }

  .section.section-overflow { overflow: hidden; }
}

.section-about {
  background-color: $bg-light;
  padding-top: 100px;
  padding-bottom: 0;

  @include media-breakpoint-up(lg) { padding-top: 120px; }

  .container {
    padding: 24px;
    @include media-breakpoint-up(lg) { padding: 72px; }
  }

  .section-title {
    font-size: 54px;
    @include media-breakpoint-up(lg) { font-size: 80px; }
  }
}

.about-item {
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h6 {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: .6;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      font-family: $ff-sans;
      font-size: 54px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 64px;
    }
  }

  p {
    width: 160px;
    font-size: 16px;
    font-family: $ff-text;
    margin-bottom: 0;
    color: $tx-light;
    line-height: 1.3;

    @include media-breakpoint-up(lg) { font-size: 18px; }
  }
}

.card-team {
  flex-basis: 21%;
  max-width: 21%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-width: 0;
  background-color: transparent;

  .card-img {
    position: relative;
    padding-top: 10px;
    padding-left: 10px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 10px;
      bottom: 10px;
      border: 2px solid $ui-secondary;
    }

    img {
      width: 100%;
      height: auto;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
  }

  .card-body {
    padding: 20px 0 0;
  }

  .card-title {
    font-family: $ff-sans;
    font-weight: 400;
    font-size: 26px;
  }

  .card-text {
    font-family: $ff-text;
    color: $tx-light;
  }

  .link {
    font-family: $ff-serif;
    font-size: 16px;
    color: $ui-secondary;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

@media (max-width: 991px) {
  .card-team {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.modal-team {
  .modal-content {
    padding: 20px;
    border-width: 0;
    border-radius: 3px;
    background-color: $bg-light;
  }

  .modal-body {
    padding: 40px;
    border: 1.5px solid $ui-secondary;
    position: relative;

    .btn-close {
      position: absolute;
      top: 10px;
      right: 10px;
      transform: scale(.9);
    }
  }

  .media {
    display: flex;
    margin-bottom: 30px;
  }

  .media-img {
    width: 200px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .media-body { margin-left: 20px; }

  .media-title {
    font-family: $ff-sans;
    font-weight: 400;
    font-size: 24px;

    @include media-breakpoint-up(lg) { font-size: 26px; }
  }

  .media-text {
    font-family: $ff-text;
    font-size: 14px;
    color: $tx-light;

    @include media-breakpoint-up(lg) { font-size: 16px; }
  }

  .modal-text {
    font-family: $ff-text;
    font-size: 14px;

    @include media-breakpoint-up(lg) { font-size: 16px; }

    //&:last-child { margin-bottom: 0; }
  }
}
