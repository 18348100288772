:root {
  --bs-btn-hover-color:         #fff;
  --bs-btn-hover-bg:            #e8c54c;
}

$grid-gutter-width:           2.5rem;

// Background
$bg-dark:                     #30353E;
$bg-light:                    #F5F2E6;

// Text
$tx-dark:                     #1D242B;
$tx-light:                    #98999B;

// Main
$ui-primary:                  #236092;
$ui-secondary:                #DEAD00;

// Border
$bd-color:                    #DCDDDE;

// Height
$ht-base:                     56px;

// Fonts
$ff-sans:                     'Alegreya Sans SC', sans-serif;
$ff-serif:                    'Alegreya SC', serif;

$ff-primary:                  'Alegreya Sans', sans-serif;
$ff-secondary:                'Alegreya', serif;
$ff-text:                     'Georgia', serif;

$font-family-base:            $ff-primary;
