
.btn {
  height: 50px;
  border-radius: 0;
  text-transform: uppercase;

  @media (min-width: 1440px) {
    height: $ht-base;
  }
}

.btn-default {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  background-color: $ui-secondary;
  color: #fff;
  font-family: $ff-serif;
  font-weight: 500;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));

  &:hover,
  &:focus {
    color: #fff;
    background-color: #e8c54c;
    box-shadow: none;
  }

  &:active {
    background-color: #B58E03;
    color: #fff;
    box-shadow: none;
    filter: none;
  }
}

.link {
  font-family: $ff-serif;
  font-size: 16px;
  color: $ui-secondary;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $ui-secondary;
    text-decoration: underline;
  }
}
