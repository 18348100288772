
.footer {
  background-color: $bg-dark;

  .container {
    max-width: 1440px;
    padding: 60px 24px 30px;
    color: #fff;

    @include media-breakpoint-up(lg) {
      padding: 72px 72px 40px;
    }
  }
}

.footer-logo {
  display: block;
  width: 180px;
  margin-bottom: 48px;

  @include media-breakpoint-up(lg) { margin-bottom: 0; }

  @media (min-width: 1440px) {
    width: 215px;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.address-item {
  width: 75%;
  font-family: $ff-text;

  @include media-breakpoint-up(lg) { width: auto; }

  h6 { font-weight: 700; }
  p { margin-bottom: 0; }

  + .address-item { margin-top: 40px; }
}

.nav-footer {
  flex-direction: column;
  margin-bottom: 48px;

  @include media-breakpoint-up(lg) {
    width: 100px;
    margin: auto;
  }

  .nav-link {
    padding: 0;
    font-family: $ff-sans;
    font-size: 20px;
    color: #fff;
    text-decoration: underline;
    line-height: 1.4;

    @media (min-width: 1440px) { font-size: 23px; }

    + .nav-link {
      margin-top: 25px;
      @media (min-width: 1440px) { margin-top: 20px; }
    }
  }
}

.nav-footer-icon {
  margin-top: 48px;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
    justify-content: flex-end;
  }

  .nav-link {
    padding: 0;
    color: #fff;
    display: block;
    font-size: 20px;

    @media (min-width: 1440px) { font-size: 24px; }

    + .nav-link { margin-left: 24px; }
  }
}

.footer-item {
  display: flex;
  align-items: center;
  font-family: $ff-text;
  font-weight: 400;

   a,span {
    position: relative;
    color: $tx-light;

    + a, + span {
      margin-left: 10px;
      padding-left: 10px;

      &::before {
        content: '';
        position: absolute;
        top: 2px;
        bottom: 2px;
        left: 0;
        border-left: 1px solid rgba($bd-color, .6);
      }
    }
  }

  + .footer-item {
    margin-top: 10px;
    @include media-breakpoint-up(lg) { margin-top: 0; }
  }
}
