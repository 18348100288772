
.form-group {
  position: relative;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .form-label {
    display: block;
    font-size: 18px;
    font-family: $ff-text;
    font-weight: 400;
    margin-bottom: 0;
    color: $tx-light;
    line-height: 1;
    transform: translateY(25px);
  }

  .form-control {
    border-width: 0;
    border-bottom-width: 1px;
    border-radius: 0;
    height: 38px;
    font-family: $ff-text;
    font-size: 18px;
    padding-left: 0;
    padding-bottom: 10px;
    background-color: transparent;
    position: relative;

    &:focus,
    &:active {
      box-shadow: none;
      border-bottom-color: $bg-dark;
    }
  }

  &.focus {
    .form-label {
      transform: none;
      font-size: 15px;
    }
  }
}
