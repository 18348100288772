// Fonts
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+SC:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@300;400;500;700&display=swap');

// Variables & Mixins
@import "variables";
@import "mixins";

// Bootstrap Framework
@import "../../node_modules/bootstrap/scss/bootstrap";

// Components
@import "components/buttons";
@import "components/form";
@import "components/labels";
@import "components/util";

// Panels
@import "panels/header";
@import "panels/footer";
@import "panels/section";

// Pages
@import "pages/home";
@import "pages/about";
@import "pages/location";
@import "pages/lifestyle";
@import "pages/house";
@import "pages/error";
//@import "pages/styleguide";
